import React, { useState } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import {
  Breakpoint,
  Modal,
  ModalDialog,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  Box,
} from '@smooth-ui/core-sc'
import { RemoveScroll } from 'react-remove-scroll'
import { connect } from 'react-redux'

import {
  StyledHeader,
  StyledGrid,
  StyledLinks,
  ToggleButton,
  ScrollLink,
} from './styles'
import { theme } from '../../style/theme'
import { LoginForm } from '../LoginForm/LoginForm';
import { login, logout } from '../../actions/actions'

const HeaderWithoutData = ({ onSelect, selected, pathname, user, dispatch }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const isHome = pathname === '/'
  const { logo } = useStaticQuery(graphql`
    {
      logo: file(name: { eq: "logo" }, ext: { eq: ".svg" }) {
        publicURL
      }
    }
  `)
  const handleClick = index => () => {
    onSelect(index)
    setMenuOpen(false)
  }

  return (
    <StyledHeader>
      <StyledGrid>
        <Breakpoint down="lg">
          <ToggleButton onClick={() => setMenuOpen(!menuOpen)} aria-label='toggle menu button'/>
        </Breakpoint>
        <Link to="/" state={{ selectedSection: 0 }} aria-label='sport me logo'>
          <img src={logo.publicURL} css={{ display: 'block' }} />
        </Link>
        {isHome ? (
          <RemoveScroll enabled={menuOpen}>
            <StyledLinks menuOpen={menuOpen}>
              <ScrollLink onClick={handleClick(0)} selected={selected === 0}
                          tabindex='0' aria-label='link to get the app'>
                Get the app
              </ScrollLink>
              <ScrollLink onClick={handleClick(1)} selected={selected === 1}
                          tabindex='0' aria-label='link to how it works'>
                How it works
              </ScrollLink>
              <ScrollLink onClick={handleClick(2)} selected={selected === 2}
                          tabindex='0' aria-label='link to pace calculator'>
                Pace calculator
              </ScrollLink>
              <Link
                to="/blog"
                activeStyle={{ color: theme.primary }}
                partiallyActive={true}
                aria-label='link to blog'
              >
                <ScrollLink as="span" selected={selected === 3}>
                  Blog
                </ScrollLink>
              </Link>
              <ScrollLink onClick={handleClick(4)} selected={selected === 4} aria-label='link to reviews'>
                Reviews
              </ScrollLink>
              <ScrollLink onClick={handleClick(5)} selected={selected === 5} aria-label='link to contact us'>
                Contact us
              </ScrollLink>
              <a href={`${process.env.GATSBY_SPORTME}/login`} aria-label='link to log in'>
                <ScrollLink as="span">
                  Log in
                </ScrollLink>
              </a>
              <a href={`${process.env.GATSBY_SPORTME}/sign-up`} aria-label='link to sign up'>
                <ScrollLink as="span">
                  Sign up
                </ScrollLink>
              </a>
            </StyledLinks>
          </RemoveScroll>
        ) : (
          <RemoveScroll enabled={menuOpen}>
            <StyledLinks menuOpen={menuOpen}>
              <Link to="/" state={{ selectedSection: 0 }} aria-label='link to get the app'>
                Get the app
              </Link>
              <Link to="/" state={{ selectedSection: 1 }} aria-label='link to how it works'>
                How it works
              </Link>
              <Link to="/" state={{ selectedSection: 2 }} aria-label='link to pace calculator'>
                Pace calculator
              </Link>
              <Link
                to="/blog"
                activeStyle={{ color: theme.primary }}
                partiallyActive={true}
                aria-label='link to blog'
              >
                Blog
              </Link>
              <Link to="/" state={{ selectedSection: 4 }} aria-label='link to reviews'>
                Reviews
              </Link>
              <Link to="/" state={{ selectedSection: 5 }} aria-label='link to contact us'>
                Contact us
              </Link>
              <a href={`${process.env.GATSBY_SPORTME}/login`} aria-label='link to log in'>
                <ScrollLink as="span">
                  Log in
                </ScrollLink>
              </a>
              <a href={`${process.env.GATSBY_SPORTME}/sign-up`} aria-label='link to sign up'>
                <ScrollLink as="span">
                  Sign up
                </ScrollLink>
              </a>
            </StyledLinks>
          </RemoveScroll>
        )}
      </StyledGrid>

      <Modal opened={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalDialog>
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>
              <h2>Log In</h2>
            </ModalHeader>
            <ModalBody>
              <LoginForm onSuccess={(user) => {
                dispatch(login(user))
                setModalOpen(false)
                navigate('/')
              }} />
            </ModalBody>
          </ModalContent>
        </ModalDialog>
      </Modal>
    </StyledHeader>
  )
}

export const Header = connect(state => state)(HeaderWithoutData)